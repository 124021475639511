import { ENTDWindow } from "../../ENTDWindow";

declare const window: ENTDWindow;

export const trackView = (testingVariant: string | null | undefined, testingName: string | null | undefined): void => {
    if (testingVariant && testingName) {
        window.dataLayer?.push({
            event: "testing.userAssigned",
            testing_domain: "entd",
            testing_name: testingName,
            testing_variant: testingVariant,
        });
    }
};
