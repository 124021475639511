import { ABTestEvent, ENTD, ENTDWindow, site_variation, test } from "../../ENTDWindow";
import { DomainEvent } from "@cube/tracking-library-api";

declare const window: ENTDWindow;

export const trackView = (testingVariant: string | null | undefined, testName: string | null | undefined): void => {
    if (testingVariant && testName) {
        const event: ABTestEvent = {
            event_name: "ab_test",
            responsible_team_feature: ENTD,
            responsible_team_tracking: ENTD,
            event_data: {
                event_category: site_variation,
                event_type: test,
                is_interaction_event: false,
                event_context: testName,
                event_content: [testingVariant],
            },
        };
        pushToLoadedDataLayer(event);
    }
};

const pushToLoadedDataLayer = (event: ABTestEvent): void => {
    const push = (event: ABTestEvent): void => {
        if (window.CubeDataLayer) {
            window.CubeDataLayer.push(event as DomainEvent);
        }
    };
    if (window.CubeDataLayer) {
        push(event);
    } else {
        window.addEventListener("DOMContentLoaded", () => push(event), {
            once: true,
            passive: true,
        });
    }
};
